.my-cart-container {
  align-items: center;
  margin: 20px auto;
  display: grid;
  grid-template-columns: 800px 400px;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;

  .my-orders {
    .cart-items-card {
      display: grid;
      grid-template-columns: 140px 1fr 30px;
      margin-bottom: 20px;
      // border-bottom: 1px solid #dfcfcf;
      padding-bottom: 10px;
      .order-image {
        width: 120px;
        margin-right: 20px;
      }
    }
    .order-buttons {
      display: flex;
      justify-content: flex-end;
    }
  }
  .cart-order-summary {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }
}

.cart-con {
  padding: 10px;
}
.shipping-order-con {
  width: 85%;
  padding: 2rem;
}
.cart-img {
  width: 100%;
  height: 200px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: rgba(50, 50, 93, 0.158) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.14) 0px 30px 60px -30px;
}
.cart-items-detail-con {
  display: flex;
  justify-content: space-between;
  .item-toal-price-con {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
