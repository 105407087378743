@import "../../styles/responsive.scss";
.about-content {
  padding: 3rem 10rem;
  @include respond-to("small") {
    padding: 1rem;
  }
  p {
    font-style: normal;
    line-height: 120%;
    text-align: justify;
    padding-bottom: 7px;
  }
}
.cont-right {
  background-color: #f5f5f5;
  padding: 2rem 2rem;
  width: fit-content;
  img {
    margin-bottom: 1rem;
  }
}
