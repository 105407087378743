@import "../../styles/responsive.scss";
@import "../../styles/variables.scss";
$loading-indicator-background: rgba(255, 255, 255, 0.9);

.loading-indicator {
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  background: $loading-indicator-background;
  z-index: 7;
  @include respond-to("small") {
    padding: 0px;
    z-index: 9999;
  }
  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  .loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    border-top: 4px solid #040000;
    border-right: 4px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border-left: 4px solid $primary;
    border-bottom: 4px solid transparent;
    animation: rotation 0.5s linear infinite reverse;
  }
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
