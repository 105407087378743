@import "../../styles/responsive.scss";
.item-qty {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #d6d2d2;
  width: fit-content;
  padding: 5px;
  border-radius: 10px;
  margin-top: 1rem;
  @include respond-to("small") {
    flex-direction: column;
    padding: 0px;
    margin-top: 0px;
    width: 50px;
  }
  .count-btn-con {
    border-right: 1px solid #d6d2d2;
    border-left: 1px solid #d6d2d2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 30px;
  }
}
