@import "../../styles/responsive.scss";

.search-options-cards {
  width: 55%;
  padding: 20px;
  color: white;
  @include respond-to("small") {
    width: 90%;
  }
}
.search-con {
  background-image: url("../../resources/blue-background.jpg");
  background-position: right;
  position: relative;
  padding-bottom: 5rem;
  background-size: cover;
  padding: 1rem;
}
.content-img {
  width: 100%;
  height: 80%;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
.second-search-option-con {
  display: flex;
  margin: 20px 73px 0;
  box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  background: #fff;
  // padding: 40px;
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin-left: 3rem;
  overflow: hidden;
  .spare-parts-left {
    border-right: 3px solid #e5e5e5;
    width: 33%;
    text-align: center;
    padding: 40px;
    box-sizing: border-box;
    position: relative;
  }
  .spare-parts-right {
    padding: 40px 55px;
    width: 67%;
    position: relative;
  }
}
.si-pagination {
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}
.sub-content-con {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2rem;
  background-color: white;
  width: 1000px;
}
.category-card {
  width: 300px;
  background-image: url("../../resources/metal-background.jpg");
  height: 300px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  padding: 2rem;
}
.filter-background {
  height: 300px;
  width: 100%;
  background-color: aliceblue;
  position: relative;
}
.explore-items-container {
  h2 {
    padding: 0px 2rem;
  }
  .flex-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
}
.cards-with-accordion {
  padding: 10px;
  .title {
    background: #102f77;
    align-items: center;
    padding: 20px 10px;
    display: flex;
    color: white;
    margin-bottom: 20px;
  }
  .card-list {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin-top: 180px;
  }
}
.card-img-con {
  display: flex;
  justify-content: flex-end;
  .card-img {
    width: 80%;
    height: 300px;
    border-radius: 10px;
  }
}
.item-product-con {
  // width: 80%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  min-height: 340px;
  height: auto;
  background-color: white;
  padding: 1.5rem;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;   
  &:hover {
    box-shadow: none;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    transition: all 0.2s ease-in;
    cursor: pointer;
  }
  img {
    width: 100%;
    border-radius: 10px;
  }
  .sale-img {
    width: 300px;
    border-radius: 8px;
    height: 200px;
    &:hover {
      transform: scale(1.3);
      transition: all 0.5s ease-in;
      margin-bottom: 20px;
    }
  }
  .price {
    font-size: 18px;
    font-weight: bold;
  }
  .discount {
    color: green;
  }
  .ic-footer {
    position: absolute;
    bottom: 20px;
    width: 85%;
  }
}
.product-prize-con {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  border-top: 1px solid rgb(224, 221, 221);
  padding-top: 20px;
  margin-top: 10px;
}
.card-actions {
  display: flex;
  align-items: center;
  gap: 20px;
}

.search-card-container {
  cursor: pointer;
  padding: 10px;
  width: 250px;
  height: 250px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  .img-con {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: skyblue;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .search-card-icon {
    width: 100px;
  }
}
.search-list-con {
  background: white;
  padding: 10px;
  margin: auto;
  margin-top: 5px;
  overflow: auto;
  max-height: 130px;
  border-radius: 10px;
  .search-item {
    cursor: pointer;
    color: black;
    padding: 5px;
    padding-left: 10px;
    transition: background-color 0.3s ease;
  }
  .search-item:hover {
    // background-color: rgb(222, 221, 221);
    background-color: #f0f0f0;
    border-radius: 2px;
  }
}
.search-list-con::-webkit-scrollbar {
  display: none;
}
/* Hiding scrollbar for IE, Edge and Firefox */
.search-list-con {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.page-load {
  animation: fadeIn 1s ease-out;
}

.scroll-transition {
  transition: all 0.3s ease-in-out;
}
