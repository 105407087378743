@import "../../styles/responsive.scss";
.mc-image {
  width: auto;
  height: 400px;
  @include respond-to("small") {
    width: auto;
    height: 310px;
  }
}
.visulize-engine-con {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.add-order {
  border-right: 1px solid gray;
  @include respond-to("small") {
    border-right: none;
  }
}
