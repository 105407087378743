@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";
.new-home-img {
  width: 100%;
}
.banner {
  background: $bg-linear-blue;
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: center;
  @include respond-to("small") {
    padding: 10px;
    h2 {
      font-size: 14px;
      margin: 0px;
      font-weight: 200;
      text-align: center;
    }
  }
}
.effe-con {
  background-color: $primary-color;
  padding: 8rem 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  @include respond-to("small") {
    padding: 1rem 5px;
  }
}
.quality-con {
  background-color: rgba(64, 64, 64, 0.597);
  color: white;
  padding: 8rem 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include respond-to("small") {
    padding: 1rem 5px;
  }
}
.circle-logo {
  border: 4px solid white;
  width: fit-content;
  border-radius: 50%;
  padding: 2rem;
  @include respond-to("small") {
    padding: 1rem;
    border-width: 2px;
  }
  img {
    width: 100px;
    @include respond-to("small") {
      width: 50px;
    }
  }
}
.news-con {
  color: black;
  padding: 1rem 2rem;
  max-height: 500px;
  overflow: auto;
  @include respond-to("small") {
    padding: 1rem;
  }
  .news-card {
    padding-bottom: 2rem;
    padding-top: 1rem;
    border-bottom: 1px solid #d8d9d8;
    @include respond-to("small") {
      padding-top: 10px;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }
    .title-con {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.category-con {
  background: linear-gradient(to bottom, #fff, #b9b9b9);
  border-right: 1px solid gray;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  transition: transform 0.3s ease;
  @include respond-to("small") {
    font-size: 10px;
    font-weight: 200;
    margin: 0.2rem 2rem;
  }
  img {
    width: 230px;
    height: 260px;
    transition: transform 0.3s ease;
    @include respond-to("small") {
      width: 140px;
      height: 140px;
    }
  }
}
.category-con:hover {
  background: $bg-linear-blue;
  opacity: 0.9;
  color: white;
  img {
    transform: scale(1.1);
    opacity: 0.8;
  }
}
.navbar-con {
  font-family: "Neue Helvetica W05", "Helvetica Neue", "Helvetica",
    "Arial,sans-serif";
  display: flex;
  color: white;
  align-items: center;
  padding: 15px 3rem;
  transition: border ease-in 1s;
  max-width: 100%;
  margin: auto;
  z-index: 1;
  justify-content: space-between;
  // border-bottom: 1px solid gray;
  // background-color: #cf372d;
  background-image: url("../../resources/spare-part-bg.jpg");
  // transition: all ease-in 0.2s;
  background-size: cover;
  img {
    margin-right: 3rem;
  }

  .tabs-con {
    display: flex;
    grid-gap: 3rem;
    float: right;
    align-items: center;
    .label-tab {
      font-weight: 600;
      transition: color 0.3s ease, transform 0.3s ease;
      &:hover,
      &.active-tab {
        color: #cf372d;
        transform: scale(1.1);
      }
    }
  }
  .label-with-arrow {
    display: flex;
    align-items: center;
  }
}

.nav-hover {
  background-color: white;
  color: black;
  border-bottom: 1px solid black;
  background-image: none;
}
.navbar-con:hover {
  background-color: white;
  color: black;
  background-image: none;
  border-bottom: 1px solid gray;
}
.tab-content-con {
  /* top: 100%; */
  z-index: 5;
  right: 5%;
  /* margin: auto; */
  width: fit-content;
  border: 1px solid lightgray;
  box-sizing: border-box;
  /* padding: 3rem 5rem; */
  display: flex;
  flex-direction: column;
  border-top: none;
  align-items: center;
  color: black;
  overflow: auto;
  position: absolute;
  padding-bottom: 5px;

  backface-visibility: hidden;
  /* height: min-content; */
  background-color: white;
  .subsection-con {
    display: flex;

    .subsection {
      // margin-right: 10rem;
      text-align: center;
      margin: 0px 5rem;
    }
  }

  .section-links:hover {
    // border-bottom: 1px solid #cf372d;
    cursor: pointer;
    color: #cf372d;
  }
}
.primary-text {
  color: $primary-color;
  @include respond-to("small") {
    font-size: 15px;
  }
}
.body-text {
  @include respond-to("small") {
    font-size: 10px;
  }
}
.news-modal {
  width: 80% !important;
  margin-bottom: 5rem !important ;
}
