.item-details-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  .item-details-image {
    width: 380px;
    height: auto;
    margin-top: 20px;
  }
  .item-title {
    font-size: 20px;
    font-weight: bold;
  }

  .item-price {
    font-size: 24px;
    font-weight: bold;
    .price-unit {
      font-size: 20px;
      font-weight: normal;
    }
  }
  .item-info-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px 50px;
    width: 35rem;
  }

  .item-actions {
    margin-top: 20px;
    display: flex;
    gap: 20px;
  }
  .shipping-order-con {
    width: 300px;
    padding: 1rem;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    .price-summary {
      display: flex;
      justify-content: space-between;
    }
  }
}
.add-to-cart-qty-con {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
