@import "../../styles/responsive.scss";
.admin-dashboard-container {
  padding: 20px 2rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-wrap: wrap;
  @include respond-to("small") {
    flex-wrap: wrap;
    margin-top: 0px;
    padding: 10px 1rem;
  }
  .nav-button {
    display: flex;
    padding: 0px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 160px;
    border: 1.5px solid var(--Primary-500, #005ce8);
    background: var(--Gray-White, #fff);
    .nav-btn-text {
      color: #005ce8;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px; /* 285.714% */
      text-transform: capitalize;
    }
  }
  .msg-info {
    color: var(--Gray-600, #626c70);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  .user-name {
    color: var(--Gray-900, #191b1c);
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
  }
  .main-card {
    display: flex;
    height: 300px;
    padding: 32px;
    gap: 24px;
    flex-shrink: 0;
    border-radius: 8px;
    background: rgba(0, 92, 232, 0.07);
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.03);
    position: relative;
    flex-wrap: wrap;
    @include respond-to("small") {
      height: auto;
      padding: 10px;
    }
    .mc-info {
      max-width: 551px;
      color: var(--Gray-600, #626c70);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: justify;
    }
    .mc-buttons {
      display: flex;
      gap: 20px;
      @include respond-to("small") {
        flex-direction: column;
      }
    }
    .mc-image-container {
      @include respond-to("large") {
        position: absolute;
        top: -70px;
        right: 100px;
      }
    }
    .mc-image {
      width: auto;
      height: 400px;
      @include respond-to("small") {
        width: 150px;
        height: 210px;
      }
    }
  }
  .dashboard-card-list {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    .dashboard-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background: var(--Gray-White, #fff);
      box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.03);
      width: 300px;
      padding: 20px;
      height: 264px;
      gap: 24px;
      .card-title {
        color: var(--Gray-900, #191b1c);
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 133.333% */
      }
      .card-info {
        color: var(--Gray-600, #626c70);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
  }
}
