@import "../../styles/responsive.scss";
@import "../../styles/variables.scss";

.register-container {
  background: $primary;
  padding: 20px;
  height: calc(100vh - 40px);
  .title {
    margin-left: 20px;
    color: white;
  }
  .welcome-info-container {
    background-color: white;
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 500px;
    border-radius: 10px;
    margin: 20px;
    padding-left: 20px;
    position: relative;
    height: 550px;
    .warning-text {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 10px;
      color: red;
    }
    .w-image-container {
      display: flex;
      align-items: center;
    }
    .w-message-container {
      margin-bottom: 20px;
    }
    .info-image {
      width: 300px;
      margin: auto;
      padding: 20px;
    }
    .right-section {
      position: relative;
      box-shadow: 3px 2px 14px 2px rgba(167, 165, 165, 0.67);
      .login-card {
        position: absolute;
        top: -65px;
        background: white;
        height: 680px;
        width: 512px;
        box-shadow: rgba(64, 64, 65, 0.2) 0px 7px 29px 0px;
        clip-path: polygon(0 2%, 100% 0, 100% 100%, 0 99%);
        border-radius: 10px;
      }
      .login-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        grid-gap: 20px;
        width: 420px;
        margin: auto;
        .sim-logo {
          margin-top: 50px;
          margin-bottom: 10px;
          width: 200px;
        }
        .logo-container {
          text-align: center;
        }
        .title {
          font-style: normal;
          font-weight: bold;
          font-size: 48px;
          line-height: 40px;
          letter-spacing: 0.1px;
          color: #373f41;
        }
        .google-captcha-key {
          // display: flex;
          // align-items: center;
          // justify-content: center;
        }
      }
    }
  }
}
