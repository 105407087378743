@import "../../styles/responsive.scss";
.manual-list-con {
  margin-top: 20px;
}
.top-name-con {
  display: flex;
  align-items: center;
  // margin-bottom: 10px;
}
.service-manual-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  height: 269px;
  height: auto;
  padding: 1rem;
  margin-bottom: 30px;
  @include respond-to("small") {
    margin-bottom: 0px;
  }
  .card-video {
    border-radius: 15px;
    transition: all ease-out 0.2s;
    cursor: pointer;
    background: rgb(193, 191, 191);
    max-height: 269px;
    width: 100%;
    object-fit: cover;
    height: 269px;
    @include respond-to("small") {
      height: 200px;
      border-radius: 10px;
    }
  }
  .card-video:hover {
    border-radius: 0px;
  }
  .created-date-con {
    display: flex;
    margin-top: 8px !important;
    align-items: center;
  }
  .desc {
    text-transform: uppercase;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 20px;
    margin-bottom: 3px;
    font-size: large;
    word-break: break-all;
  }
  .manual-name {
    margin: 0px;
    margin-left: 10px;
    color: gray;
    word-break: break-all;
  }
}
.manual-con {
  margin: 20px auto 50px auto;
  width: 90%;

  .video-con {
    // border: 1px solid gray;
    // background-color: black;
    overflow: hidden;
  }
}
.main-manual-search-con {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 3rem;
  @include respond-to("small") {
    flex-wrap: wrap;
    margin: 10px;
  }
  .manual-search {
    width: 40%;
    @include respond-to("small") {
      width: 100%;
    }
  }
}
.manual-search-con {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 30px auto 50px auto;
}
.manual-search-list-con {
  position: absolute;
  background-color: white;
  padding: 10px;
  margin: auto;
  margin-top: 5px;
  overflow: auto;
  max-height: 130px;
  border-radius: 10px;
  .search-item {
    cursor: pointer;
    color: black;
    padding: 5px;
    padding-left: 10px;
  }
  .search-item:hover {
    background-color: rgb(222, 221, 221);
    border-radius: 2px;
  }
}
.small-video-con {
  display: flex;
  // background: rgb(54, 54, 54);
  // color: white;
  // padding: 20px;
  // border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
  .preview-video {
    max-width: 180px;
    height: 100px;
    object-fit: cover;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid white;
    @include respond-to("small") {
      max-width: 120px;
      height: 70px;
    }
  }
  .small-active-video {
    border: 1px solid #d32f2f;
  }
  .video-desc {
    margin: 0px;
    margin-left: 10px;
    word-break: break-all;
    margin-top: 5px;
    color: rgb(98, 97, 97);
  }
  .video-created-time {
    font-size: small;
  }
  .small-video-title {
    margin: 0px;
    margin-left: 10px;
    word-break: break-all;
    font-size: large;
  }
}

.side-video-con {
  height: 500px;
  overflow: auto;
  padding-right: 20px;
}
.active-video-desc {
  // text-transform: uppercase;
  word-break: break-all;
  color: rgb(98, 98, 98);
  font-size: 20px;
}
