@import "../../styles/responsive.scss";
.leadership-content {
  padding: 3rem 5rem;
  @include respond-to("small") {
    padding: 1rem;
  }
  p {
    text-align: justify;
    padding-bottom: 7px;
  }
  .learship-card-con {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dbdbd9;
    padding: 1rem 0.5rem;
    margin-bottom: 1.5rem;
    @include respond-to("small") {
      padding: 0px;
    }
  }
}
