@import "../../styles/variables.scss";
.mui-nav-con {
  display: flex;
  align-items: center;
  max-width: 100%;
  padding: 3px 2rem;
  justify-content: space-between;
  background-color: white;
  &:hover {
    color: black;
    border-bottom: 1px solid rgb(194, 193, 193);
    transition: all 0.2s ease-in;
  }
  .nav-label-cls {
    cursor: pointer;
    font-size: 13px;
    padding: 1px 5px;
    &:hover {
      color: $primary-color;
    }
  }
  .active-label {
    color: $primary-color;
    border-bottom: 3px solid $primary-color;
    background-color: #e6eaf1;
  }
}
.sub-menu-links-con {
  padding: 1rem;

  .sub-menu-nav-link {
    &:hover {
      color: $primary-color;
      cursor: pointer;
      transition: all 0.2s ease-in;
    }
  }
}
.logo-bg {
  background-color: white;
  cursor: pointer;
  .logo-img {
    height: 40px;
    width: auto;
  }
}

.active-list {
  background-color: #005ce814;
  border-left: 5px solid #306fff;
  // color: white;
}
