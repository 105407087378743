@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";
.footer {
  background: $bg-linear-blue;
  color: #fff;
  padding: 1rem 5rem;
  text-align: center;
  bottom: 0;
  @include respond-to("small") {
    padding: 10px;
  }
  .footer-title {
    @include respond-to("small") {
      font-size: 18px;
      font-weight: 500;
    }
  }
  .product-con {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    @include respond-to("small") {
      margin-bottom: 5px;
    }
  }
  .menu-bottom {
    margin: 0 auto;
    width: 950px;
    padding: 0px;
    display: flex;
    justify-content: space-around;
    cursor: pointer;
    @include respond-to("small") {
      width: auto;
      padding: 10px;
      flex-wrap: wrap;
    }
    .link-text {
      text-transform: uppercase;
      font-size: 14px;
      @include respond-to("small") {
        margin: 5px;
        font-size: 10px;
      }
    }
    .link-text:hover {
      text-decoration: underline;
    }
  }
}
