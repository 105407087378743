@import "../../styles/variables.scss";
@import "../../styles/responsive.scss";
.visualizeProductsCon {
  padding: 10px 20px;
  .sub-parts-btn-con {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .engine-list-label-con {
    display: flex;
    align-items: center;

    p {
      margin: 0px;
    }
  }
  .preview-con {
    height: 500px;
    width: 100%;
    background-color: $bg-main;
    display: flex;
    border-radius: 20px;
    p {
      margin: auto;
      margin-left: 50%;
    }
    .btn-con {
      display: flex;
      width: -webkit-fill-available;
      align-items: flex-start;
      justify-content: flex-end;
      margin: 10px;
    }
  }
  .parts-con {
    height: 100%;
    overflow: auto;
  }
  .border-r {
    border-right: 1px solid rgba(128, 128, 128, 0.606);
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .list-item {
    padding: 7px;
    width: 95%;
    font-size: 13px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.3s ease;
    animation: fadeIn 0.8s ease-out;
  }
  .list-item:hover {
    background-color: $bg-main;
  }
  .sm-mb {
    @include respond-to("small") {
      margin-bottom: 1rem;
    }
  }
  .active-item {
    color: white !important;
    background-color: $primary-color !important;
  }
  .active-item:hover {
    color: white;
    background-color: $primary-color;
  }
  .radio-buttons-group-label {
    margin: 5px;
    font-size: 20px;
    color: $primary-color;
    text-align: center;
  }
  .sub-part-con {
    p {
      font-size: 20px;
    }
  }
  .icon-class {
    transform: rotate(90deg);
  }
  /* For WebKit browsers (Chrome, Safari) */
  .parts-con::-webkit-scrollbar {
    width: 0px; /* Set the width of the scrollbar */
  }

  .parts-con::-webkit-scrollbar-thumb {
    background-color: #f7f5f5; /* Set the color of the thumb */
  }

  .parts-con::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Set the color of the track */
  }
}

ul {
  list-style-type: none;
  padding: 0;
}

#main-parts {
  height: 100%;
  overflow-y: scroll;
  /* border: 1px solid black; */
}

.part,
.display-part {
  cursor: pointer;
  padding: 10px;
}
.hidden-part {
  display: none;
}
.part:hover {
  background-color: #eff3fc;
  color: black;
}

.active-part {
  background-color: gray;
  color: white;
}
.part-search-con {
  border-bottom: 1px solid rgb(240, 237, 237);
  margin-bottom: 10px;
}
#child-parts {
  height: 100%;
  overflow-y: scroll;
  /* border: 1px solid black; */
}
.main-container {
  padding: 30px;
  display: flex;
  gap: 20px;
}
.v3-image-container {
  height: 80vh;
  width: 100%;
  background-color: white;
  // border: 1px solid gray;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  // box-shadow: 0px 2px 12px 0px rgba(134, 126, 126, 0.75);
  display: flex;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  @include respond-to("small") {
  }
}
.instruction {
  text-align: center;
  margin-top: 20%;
  font-size: 20px;
}
.cus-button {
  padding: 5px 10px;
  border-color: #102f77;
  margin-right: 10px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  background-color: #102f77;
}
.button-list {
  display: flex;
  width: -webkit-fill-available;
  align-items: flex-start;
  justify-content: flex-end;
  margin: 10px;
  margin-right: 50px;
  position: absolute;
  bottom: 10px;
  gap: 10px;
}
.grid-container {
  display: flex;
  gap: 20px;
}

.grid-item {
  flex: 1;
}

.parts-con {
  height: 400px;
  overflow: auto;
}

.border-r {
  border-right: 1px solid gray;
}
.radio-buttons-group-label {
  font-size: 20px;
  color: #356ff5;
}
#main-parts::-webkit-scrollbar {
  width: 0px;
}

#main-parts::-webkit-scrollbar-thumb {
  background-color: #f7f5f5;
}

#main-parts::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
#child-parts::-webkit-scrollbar {
  width: 0px;
}

#child-parts::-webkit-scrollbar-thumb {
  background-color: #f7f5f5;
}

#child-parts::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

#sub-part-buttons {
  display: flex;
  gap: 10px;
}
