@import "../../styles/responsive.scss";
.release-notes-con {
  padding: 5rem;
  // margin-top: 50px;
  @include respond-to("small") {
    padding: 1rem;
  }
  .notes-con {
    background-color: white;
    border: 1px solid #ebedeb;
    margin: 0px 1rem;
    border-radius: 10px;
    max-height: 600px;
    overflow: auto;
    .title-con {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .notes-card-con {
      padding: 20px;

      .cat-con {
        display: flex;
        margin-bottom: 10px;
        .category-btn {
          box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
          padding: 5px 10px;
          border-radius: 5px;
          margin-right: 15px;
        }
        .green-btn {
          background-color: #3dba71;
        }
        .purple-btn {
          background-color: #dd68e3;
        }
        .red-btn {
          background-color: #f03f3f;
        }
      }
      .note-card {
        padding-bottom: 2rem;
        padding-top: 1rem;
        border-bottom: 1px solid #d8d9d8;
        @include respond-to("small") {
          padding-top: 10px;
          padding-bottom: 1rem;
        }
      }
    }
  }
}
