@import "../../styles/variables.scss";
.quality-points {
  list-style-type: disc;
  margin-left: 2rem;
  li {
    padding: 0.2rem;
    font-size: 14px;
    &::marker {
      font-size: 20px;
      color: $primary;
    }
  }
}
