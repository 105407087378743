@import "../../styles/variables";
@import "../../styles/responsive.scss";

.photo-viewer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .photo-preview-header {
    width: 100%;
    display: block;
    margin-bottom: 30px;
  }

  .edit-button {
    float: right;
  }
  .photo-preview {
    border: 1px solid #eaeaea;
    height: 500px;
    justify-content: center;
    width: 100%;
    display: flex;
    border-radius: 10px;
    align-items: center;
    overflow: hidden;
    @include respond-to("small") {
      height: 300px;
    }
    .preview-img {
      width: 200px;
      height: 300px;
      object-fit: contain;
      background: gray;
      cursor: pointer;
    }
  }
  .photo-list {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    .list-img {
      width: 100px;
      height: 60px;
      border-radius: 10px;
      object-fit: contain;
      background: gray;
      border: 3px solid white;
    }
    .active {
      border: 4px solid orangered;
    }
  }
  .no-image {
    padding: 40px;
  }
}
