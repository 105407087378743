@import "../../styles/responsive.scss";

.custom-modal-container {
  position: fixed;
  z-index: 6;
  padding-top: 50px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  border-radius: 8px;
  margin-bottom: 2rem;
  @include respond-to("small") {
    padding: 0px;
    z-index: 9999;
  }
  .modal-content {
    margin: auto;
    width: 500px;
    // max-width: 500px;
    background: #fff;
    border-radius: 0px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    @include respond-to("small") {
      width: 100%;
      height: 100%;
      max-width: 100%;
    }
    .modal-header {
      display: flex;
      justify-content: space-between;
      padding: 5px 24px;
      // height: 40px;
      align-items: center;
      border-bottom: 1px solid #dbdcdd;
      .modal-title {
        color: var(--Gray-900, #191b1c);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 133.333% */
      }
      .close {
        cursor: pointer;
        float: right;
      }
    }
    .modal-body {
      padding: 15px 24px;
    }
  }
}

.msg {
  .custom-modal-container {
    position: fixed;
    z-index: 6;
    padding-top: 2%;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.3);
    @include respond-to("small") {
      padding: 0px;
      z-index: 9999;
    }

    .modal-content {
      margin: auto;
      width: 90vw;
      max-width: 90vw;
      background: #fff;
      border-radius: 0px;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.3);
      @include respond-to("small") {
        width: 100%;
        height: 100%;
        max-width: 100%;
      }
      .modal-header {
        display: flex;
        justify-content: space-between;
        padding: 0px 40px;
        height: 40px;
        background-color: #fa768d;
        align-items: center;
        .modal-title {
          color: white;
          font-size: 18px;
          font-weight: bold;
          text-transform: uppercase;
        }
        .close {
          cursor: pointer;
          float: right;
        }
      }
      .modal-body {
        padding: 20px;
        background-color: grey;
      }
    }
  }
}

.audience {
  .custom-modal-container {
    position: fixed;
    z-index: 6;
    padding-top: 10%;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.3);
    @include respond-to("small") {
      padding: 0px;
      z-index: 9999;
    }

    .modal-content {
      margin: auto;
      width: 75vw;
      max-width: 75vw;
      background: #fff;
      border-radius: 0px;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.3);
      @include respond-to("small") {
        width: 100%;
        height: 100%;
        max-width: 100%;
      }
      .modal-header {
        display: flex;
        justify-content: space-between;
        padding: 0px 40px;
        height: 40px;
        background-color: #fa768d;
        align-items: center;
        .modal-title {
          color: white;
          font-size: 18px;
          font-weight: bold;
          text-transform: uppercase;
        }
        .close {
          cursor: pointer;
          float: right;
        }
      }
      .modal-body {
        padding: 20px;
        background-color: #ecf1f5;
      }
    }
  }
}
