@import "./responsive.scss";
body {
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: #f5f6f7;
  font-size: 14px !important;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  font-family: "Neue Helvetica W05", "Helvetica Neue", "Helvetica",
    "Arial,sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto !important;
  padding: 0px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.si-hide-mobile {
  @include respond-to("small") {
    display: none !important;
  }
}
.si-hide-web {
  display: none !important;
  @include respond-to("small") {
    display: inline-block !important;
  }
}

.demo-container {
  display: flex;
  gap: 20px;
}
.left-container {
  position: relative;
  width: 800px;
  height: 400px;
}
.number1 {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: red;
  top: 400px;
  left: 200px;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background: #e6e7e8;
  border-radius: 5px;
}
