@import "../../styles/responsive.scss";
.orders-con {
  padding: 10px 30px;
  @include respond-to("small") {
    padding: 10px;
  }
  .show-all-orders-con {
    table,
    th,
    td {
      border: 1px solid black;
      border-collapse: collapse;
    }
  }
}
.order-details-con {
  padding: 1rem 5rem;
  @include respond-to("small") {
    padding: 10px;
  }
  .details-body {
    padding-left: 2rem;
    @include respond-to("small") {
      padding-left: 0px;
    }
  }
  .order-id-con {
    display: flex;
    align-items: center;
  }
  .order-details-body {
    padding: 1rem;
  }
  .order-items-details-con {
    border: 2px solid rgba(229, 227, 227, 0.918);
    padding: 1rem;
    border-radius: 8px;
    background-color: white;
    margin-bottom: 1rem;
    @include respond-to("small") {
      padding: 0.5rem;
    }
  }
  .order-user-details-con {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: center;
    @include respond-to("small") {
      flex-wrap: wrap;
    }
  }
}
.my-orders-con {
  padding: 10px 50px;
  @include respond-to("small") {
    padding: 10px;
  }
}
.my-order-card-con {
  border: 2px solid rgba(229, 227, 227, 0.918);
  border-radius: 10px;
  margin-bottom: 1rem;
  overflow: hidden;
  .order-card-header {
    display: flex;
    padding: 0.5rem 2rem;
    justify-content: space-between;
    background-color: rgb(226, 227, 229);
    @include respond-to("small") {
      padding: 0.5rem;
      flex-wrap: wrap;
    }
  }
  .items-list-con {
    padding: 2rem;
    @include respond-to("small") {
      padding: 0.5rem;
    }
  }
  .item-details {
    display: flex;
    justify-content: space-between;
    @include respond-to("small") {
      flex-wrap: wrap;
    }
  }
}
.default-font {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
