@import "../../styles/responsive.scss";
.shareholder-info-con {
  padding: 2rem 10rem;
  @include respond-to("small") {
    padding: 1rem;
  }
  .file-link {
    text-decoration: none;
    color: #337ab7;
    &:hover {
      text-decoration: underline;
    }
    @include respond-to("small") {
      font-size: 15px;
    }
  }
}
.products-page {
  background-color: white;
  .box1 {
    border: 1px dotted #f5f4f0;
    width: 300px;
    height: auto;
    float: left;
    text-align: center;
    background-color: #ebebeb;
    padding: 10px;
    p {
      background: #fff;
      padding: 0.5rem;
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
      display: flex;
      border: 1px solid #d6d6d6;
      img {
        width: 100px;
        min-height: 110px;
      }
    }
    .page-link {
      &:hover {
        color: red;
        cursor: pointer;
      }
    }
  }
}
