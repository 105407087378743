@import "../../styles/responsive.scss";
.order-confirmed {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  animation: fadeIn 1s ease-out;
  @include respond-to("small") {
    padding: 0px;
  }
}

.check-mark-img {
  height: 250px;
  animation: scaleUp 1s ease-out;
  @include respond-to("small") {
    height: 100px;
  }
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: gray;
  animation: slideUp 1s ease-out;
}

.thank-you-text {
  animation: fadeInText 1s ease-out;
}

.confirmation-text {
  line-height: 1.3;
  animation: fadeInText 1s ease-out;
}

.continue-btn {
  margin-top: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
  @include respond-to("small") {
    margin-top: 50px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleUp {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInText {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
