@import "../../styles/responsive.scss";
.knowledge-base {
  .knowledge-search-con {
    padding: 6rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include respond-to("small") {
      padding: 2rem 1rem;
    }
    .know-search {
      width: 40%;
      margin-top: 2rem;
      @include respond-to("small") {
        width: 90%;
        margin-top: 1rem;
      }
    }
  }
  .knowledge-card {
    background-color: white;
    padding: 1rem 1.5rem;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    transform: perspective(1000px) rotateX(0) rotateY(0);
    height: 100%;
    @include respond-to("small") {
      padding: 0.5rem;
    }
    &:hover {
      transform: perspective(1000px) rotateX(0) rotateY(0) scale(1.05);
      box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 8px;
      cursor: pointer;
    }
  }
}
.desc-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  padding: 2rem;
  background-color: white;
  border-radius: 10px;
  border: none;
  @include respond-to("small") {
    width: 80%;
    padding: 1rem;
  }
}

.drop-in {
  animation: drop-in 1s ease 100ms backwards;
}

.drop-in-2 {
  animation: drop-in 1200ms ease 200ms backwards;
}

@keyframes drop-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translate(0px);
  }
}
