@import "../../styles/responsive.scss";
@import "../../styles/variables.scss";

.login-container {
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: relative;
  .login-direction {
    position: absolute;
    right: 220px;
    top: 50%;
    display: flex;
    flex-direction: column;
    gap: 26px;
    .nav-link {
      display: flex;
      gap: 20px;
      cursor: pointer;
      .nav-text {
        width: 174px;
        color: #fff;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-bottom: 2px solid rgba(255, 255, 255, 0.5);
        padding-bottom: 5px;
      }
    }
  }
  .login-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10%;
    @include respond-to("small") {
      padding: 20px;
      padding-top: 100px;
    }
  }
}
.oem-login-image {
  background-image: url("../../resources/images/login-page.jpeg");
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.app-logo-container {
  position: absolute;
  top: 32px;
  left: 40px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  @include respond-to("small") {
    top: 20px;
    left: 20px;
  }
  .app-logo {
    width: 250px;
  }
  .app-name {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 24px */
    text-transform: capitalize;
  }
}
.login-card-container {
  position: absolute;
  top: 30px;
  right: 20px;
  border-radius: 8px;
  background: #fff;
  backdrop-filter: blur(2px);
  width: 380px;
  height: 380px;
  flex-shrink: 0;
  padding: 20px;
  @include respond-to("small") {
    width: 85%;
    padding: 10px;
  }
  .card-title {
    color: #000;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 34px;
  }
}
