@import "../../styles/responsive.scss";
@import "../../styles/variables.scss";
.brands-con {
  padding: 2rem 5rem;
  @include respond-to("small") {
    padding: 1rem;
  }
  .milestone-table {
    table {
      background-color: transparent;
      border-collapse: collapse;
      border-spacing: 0;
      tr td {
        border: 1px solid #e5e5e5;
        padding: 10px 10px;
        color: #000;
        font-size: 15px;
      }
      tr:nth-child(even) {
        background-color: white;
      }
      .blue {
        background: $primary-color;
        color: #fff !important;
      }
    }
  }
  .brand-img-con {
    width: 80%;
    overflow: hidden;
    border-radius: 20px;
  }
}
